// $primary: #3f7f5a;
// $primary-varient: #54b97f;
$primary: #667c3e;
$primary-varient: #8ca658;
$secondary: #818589;
$secondary-varient: #ffffff1a;
$secondary-varient-two: #1bb3f7;
$dark: #1d1d1d;
$white: #FFF;
$off-white: #F0F0F0;
$secondary-off-white: #F0F0F0;
$background: #FFF;
$backgroundSurface: #ebebeb;
$error: #CF6679;