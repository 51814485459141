@media (max-width: 650px) {
  .photo-gallery-wrapper {
    .photo-gallery-intro-wrapper {
      .photo-gallery-header {
        font-size: 24px;
      }
  
      .photo-gallery-text {
        font-size: 18px;
      }
    }
  
    .photo-gallery-items-wrapper {
      .photo-gallery-item-wrapper {
        .photo-gallery-item-image-group-wrapper {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .photo-gallery-wrapper {
    .photo-gallery-intro-wrapper {
      padding: 40px 20px;
    }
  
    .photo-gallery-items-wrapper {
      .photo-gallery-item-wrapper {
        padding: 40px 20px;
      }
    }
  
    .photo-gallery-items-loading-wrapper {
      padding: 20px;
    }

    .photo-gallery-more-items-loading-wrapper {
      padding: 20px;
    }
  }
}