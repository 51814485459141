@import "../VarsAndMixins/colors.scss";

.photo-gallery-wrapper {
  padding: 40px 0px;

  .photo-gallery-intro-wrapper {
    padding: 40px;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    gap: 40px;
    text-align: center;

    .photo-gallery-header {
      font-size: 28px;
      font-weight: 600;
      color: $secondary;
    }

    .photo-gallery-text {
      font-size: 20px;
      color: $dark;
      line-height: 25px;
    }
  }

  .photo-gallery-items-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;

    .photo-gallery-item-wrapper {
      padding: 40px;
      background-color: $off-white;
      border-radius: 2.5px;
      display: grid;
      grid-template-columns: 1fr;
      gap: 40px;

      .photo-gallery-item-projet-title-wrapper {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;

        .photo-gallery-item-project-title-header {
          font-size: 18px;
          color: $secondary;
          font-weight: 600;
        }

        .photo-gallery-item-project-title {
          font-size: 16px;
          color: $dark;
          position: relative;
        }
      }

      .delete-wrapper {
        text-decoration: underline;
        color: $primary;
        cursor: pointer;
      }

      .photo-gallery-item-project-descript-wrapper {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;

        .photo-gallery-item-project-descript-header {
          font-size: 18px;
          color: $secondary;
          font-weight: 600;
        }

        .photo-gallery-item-project-descript {
          font-size: 16px;
          color: $dark;
          line-height: 25px;
        }
      }

      .photo-gallery-item-image-group-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 40px;

        .photo-gallery-item-image-group {
          width: 100%;
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: minmax(18px, 21px) 1fr;
          gap: 20px;
  
          .photo-gallery-item-image-group-header {
            font-size: 18px;
            color: $secondary;
            font-weight: 600;
          }
  
          .photo-gallery-item-image-wrapper {
            width: 100%;

            .photo-gallery-item-image {
              width: 100%;
              max-height: 60vh;
              height: 100%;
              object-fit: cover;
              border-radius: 2.5px;
            }
          }
        }
      }
    }
  }

  .photo-gallery-items-loading-wrapper {
    padding: 40px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    align-items: center;
    justify-items: center;
    background-color: $off-white;

    .photo-gallery-items-loading-header {
      font-size: 20px;
      font-weight: 600;
      color: $secondary;
    }

    .photo-gallery-items-loading-text {
      line-height: 25px;
      color: $dark;
    }

    .photo-gallery-items-loading-icon-wrapper {
      font-size: 24px;
      font-weight: 600;
      color: $secondary;
    }
  }

  .photo-gallery-more-items-loading-wrapper {
    padding: 40px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    align-items: center;
    justify-items: center;
    background-color: $off-white;

    .photo-gallery-more-items-loading-header {
      font-size: 20px;
      font-weight: 600;
      color: $secondary;
      text-align: center;
    }

    .photo-gallery-more-items-loading-icon-wrapper {
      font-size: 24px;
      font-weight: 600;
      color: $secondary;
    }
  }
}