@media (max-width: 900px) {
  .financing-container {
  
    .financing-intro-container {
      grid-template-columns: 1fr;
      background-image: none;
      background-color: #F0F0F0 !important;
      min-height: 100%;
      height: 100%;
      gap: 0px;
    }
  
    .financing-info-wrapper {
  
      .financing-info-cards-wrapper {
        grid-template-columns: 1fr 1fr;
        gap: 40px;
      }
    }
  }
}

@media (max-width: 650px) {
  .financing-container {
  
    .financing-info-wrapper {
      gap: 40px;

      .financing-info-cards-wrapper {
        grid-template-columns: 1fr;
        gap: 40px;
      }
    }
  
    .financing-faq-wrapper {
      gap: 40px;

      .financing-faq-cards-wrapper {
        grid-template-columns: 1fr;
        gap: 40px;

      }
    }
  }
}

@media (max-width: 325px) {
  .financing-container {
  
    .financing-intro-container {
      padding: 40px 20px;
      width: calc(100% - 40px);
    }
  
    .financing-info-wrapper {
      padding: 40px 20px;
      width: calc(100% - 40px);
      gap: 40px;
  
      .financing-info-cards-wrapper {
        grid-template-columns: 1fr;
        gap: 40px;
      }
    }
  
    .financing-faq-wrapper {
      padding: 40px 20px;
      width: calc(100% - 40px);
  
      .financing-faq-cards-wrapper {
        grid-template-columns: 1fr;
        gap: 40px;
      }
    }
  }
}