@import "../VarsAndMixins/colors.scss";

.page-not-found-wrapper {
  padding: 40px;

  .page-not-found-header {
    padding: 40px 0px;
    font-size: 28px;
    color: $secondary;
    font-weight: 600;
  }

  .page-not-found-text {
    font-size: 20px;
    color: $dark;
  }
}