@import "../VarsAndMixins/colors.scss";

@media (min-width: 625px) {
  .homepage-wrapper {  
    .homepage-home-hero-wrapper {
      .homepage-home-hero-image-skew-one {
        position: absolute;
        width: 100%;
        height: 300px;
        background-color: #FFF;
        transform: skewY(350deg);
        bottom: -175px;
      }
    
      .homepage-home-hero-image-skew-two {
        position: absolute;
        width: 100%;
        height: 200px;
        background-color: #FFF;
        transform: skewY(25deg);
        left: -300px;
        bottom: -100px;
      }
    }
  }  
}

@media (min-width: 1050px) {
  .homepage-wrapper {  
    .homepage-home-hero-wrapper {
      .homepage-home-hero-image-skew-two {
        transform: skewY(15deg);
        left: -400px;
      }
    }

    .homepage-services-wrapper {
      .homepage-service-wrapper {
        .homepage-service-text {
          padding-top: 40px;
        }
      }
    }
  }
}

@media (min-width: 1500px) {
  .homepage-wrapper {  
    .homepage-home-hero-wrapper {
      .homepage-home-hero-image-skew-two {
        bottom: -200px;
      }
    }
  }
}

@media (max-width: 850px) {
  .homepage-wrapper { 
    .homepage-introduction-section-wrapper {
      .homepage-introduction-container {
        grid-template-columns: 1fr;
        gap: 40px;
      }
    }

    .homepage-services-wrapper {
      .homepage-service-wrapper {
        .homepage-service-text {
          font-size: 16px;
          margin: 10px 0px;
        }
      }
    }
  }
} 

@media (max-width: 650px) { 
  .homepage-wrapper {
    .homepage-home-hero-wrapper {
      .homepage-home-hero-content-container {
        .homepage-home-hero-content-wrapper {
          .homepage-home-hero-content-header {
            width: 80%;
            font-size: 24px;
          }

          .homepage-home-hero-content-subtext {
            font-size: 20px;
          }
        }
      }
    }

    .homepage-services-wrapper {
      grid-template-columns: 1fr 1fr;
  
      .homepage-service-wrapper {
        height: calc((100vw - 50vw) - 60px + 2.5px);
  
        .homepage-service-icon-wrapper {
          max-height: 75px;
        }
      }

      .homepage-service-wrapper:nth-child(even)::after {
        display: none;
      }

      .homepage-service-wrapper:nth-child(-n + 6)::before  {
        content: "";
        position: absolute;
        left: 0px;
        bottom: -11px;
        height: 2px;
        width: 100%;
        background-color: $backgroundSurface;
      }
    }

    .homepage-outro-wrapper {
      padding: 40px;
    }
  }
}

@media (max-width: 400px) {
  .homepage-wrapper {  
    .homepage-home-hero-wrapper {
      .homepage-home-hero-content-container {
        .homepage-home-hero-content-wrapper {
          .homepage-home-hero-content-header {
            width: 100%;
            font-size: 20px;
          }

          .homepage-home-hero-content-subtext {
            font-size: 16px;
          }
        }
      }

      .homepage-home-hero-image-skew-two {
        left: -100px;
        bottom: -75px;
      }
    }

    .homepage-introduction-section-wrapper {
      padding: 20px;
      gap: 20px;

      .homepage-introduction-container {
        grid-template-columns: 1fr;
        gap: 20px;
      }
    
      .homepage-introduction-testimonials-wrapper {
        grid-template-columns: 1fr;
        overflow: hidden;

        .homepage-introduction-testimonial-wrapper {
          min-width: unset;

          .homepage-introduction-testimonial-text {
            font-size: 16px;
          }
    
          .homepage-introduction-testimonial-author {
            font-size: 14px;
          }
        }
      }
    }

    .homepage-services-wrapper {  
      padding: 20px;

      .homepage-service-wrapper {  
        .homepage-service-icon-wrapper {
          max-height: 75px;
        }

        .homepage-service-text {
          margin: 10px 0px;
        }
      }
    }

    .homepage-outro-wrapper {
      padding: 40px 20px;
      .homepage-outro-header {
        font-size: 20px;
        line-height: 30px;
      }

      .homepage-outro-text {
        font-size: 16px;
        line-height: 30px;
      }
    }
  }
}

@media (max-width: 325px) {
  .homepage-wrapper {  
    .homepage-home-hero-wrapper {
      .homepage-home-hero-content-container {
        .homepage-home-hero-content-wrapper {
          .homepage-home-hero-content-header {
            font-size: 18px;
            text-align: center;
          }

          .homepage-home-hero-content-subtext {
            font-size: 16px;
            text-align: center;
            width: calc(100% - 40px);
          }
        }
      }

      .homepage-home-hero-image-skew-two {
        left: -100px;
        bottom: -75px;
      }
    }

    .homepage-services-wrapper {  
      .homepage-service-wrapper {  
        .homepage-service-icon-wrapper {
          max-height: 75px;
        }

        .homepage-service-text {
          margin: 15px 0px;
          padding: 0px;
          font-size: 14px;
        }
      }
    }

    .homepage-outro-wrapper {
      .homepage-outro-header {
        font-size: 18px
      }
    }
  }
}

@media (max-width: 290px) {
  .homepage-wrapper {
    .homepage-services-wrapper {  
      grid-template-columns: 1fr;
  
      .homepage-service-wrapper {  
        height: calc((100vw - 25vw) - 26px + 2.5px);

        .homepage-service-icon-wrapper {
          max-height: 75px;
        }
  
        .homepage-service-text {
          margin: 15px 0px;
          padding-top: 10px;
          font-size: 16px;
        }

        &::after {
          display: none;
        }
      }

      .homepage-service-wrapper:nth-child(-n + 7)::before  {
        content: "";
        position: absolute;
        left: 0px;
        bottom: -11px;
        height: 2px;
        width: 100%;
        background-color: $backgroundSurface;
      }
    }
  }
}