@media (min-width: 800px) {
  .side-nav-link {
    font-size: 14px;
  }

  .side-nav-socials-wrapper {
    align-items: flex-start !important;
  }
}

@media (max-width: 494px) {
  .open-content {
    max-height: calc(205px + 40px + 17px) !important;
  }
}

@media (max-width: 450px) {
  .side-nav-container {
    .side-nav-wrapper {
      .side-nav-heading-wrapper {
        padding: 20px 20px 0px;

        .side-nav-close-modal-header {
          width: 20px;
          height: 20px;
        }
      }

      .side-nav-main-content {
        padding: 20px;
        height: calc(100% - 45px - 40px - 40px);
      }
    }
  }

  .open-content {
    max-height: calc(188px + 40px + 17px) !important;
  }
}

@media (max-width: 443px) {
  .open-content {
    max-height: calc(205px + 40px + 17px) !important;
  }
}

@media (max-width: 376px) {
  .open-content {
    max-height: calc(222px + 40px + 17px) !important;
  }
}

@media (max-width: 325px) {
  .open-content {
    max-height: calc(239px + 40px + 17px) !important;
  }
}

@media (max-width: 305px) {
  .open-content {
    max-height: calc(256px + 40px + 17px) !important;
  }
}

@media (max-width: 296px) {
  .open-content {
    max-height: calc(256px + 40px + 17px) !important;
  }
}