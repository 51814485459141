@import "../VarsAndMixins/colors.scss";

.book-appt-wrapper {
  padding: 40px 0px;

  .book-appt-intro-wrapper {
    padding: 40px;
    background-color: $secondary;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    gap: 40px;
    text-align: center;
    
    .book-appt-intro-header {
      font-size: 28px;
      font-weight: 600;
      color: $white;
      width: 80%;
      line-height: 40px;
    }

    .book-appt-intro-text {
      font-size: 20px;
      color: $white;
      line-height: 30px;
    }
  }

  .book-appt-main-content-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .book-appt-left-column-wrapper {
      display: grid;
      grid-template-columns: 1fr;
      gap: 25px;
      align-items: center;

      .book-appt-left-column-header {
        font-weight: 600;
        font-size: 22px;
        text-align: center;
        color: $primary;
        padding: 40px 40px 0px;
        align-self: flex-start;
      }

      .book-appt-left-column-text {
        font-size: 18px;
        color: $dark;
        font-weight: 600;
        line-height: 25px;
        text-align: center;
        padding: 0px 40px;
        align-self: flex-start;
        line-height: 30px;
      }

      .book-appt-left-column-reviews-wrapper {
        align-self: flex-start;
        display: grid;
        grid-template-columns: 1fr;
        gap: 40px;
        padding: 0px 40px;

        .book-appt-left-column-review-wrapper {
          display: grid;
          grid-template-columns: 1fr;
          gap: 20px;
          align-items: center;
          justify-items: center;
          background-color: $primary;
          padding: 40px;
          border-radius: 2.5px;

          .book-appt-left-column-review-text {
            color: $white;
            line-height: 30px;
            text-align: center;
          }

          .book-appt-left-column-review-author {
            color: $white;
            text-align: center;
            font-weight: 600;
          }
        }
      }
    }
  }

  .book-appt-form-wrapper {
    background-color: $secondary-off-white;
    padding: 40px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;

    .book-appt-form-input-group {
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;

      .book-appt-form-label {
        font-weight: 600;
        color: $dark;
        font-size: 15px;
      }

      .book-appt-form-input {
        height: 37px;
        border: 1.5px solid $secondary;
        padding: 0px 20px;
        border-radius: 2.5px;
        color: $primary;
        letter-spacing: 0.75px;
        font-weight: 600;
        font-size: 14px;

        &::placeholder {
          color: #a9a9a9;
        }

        &:focus-visible {
          outline: none;
        }
      }

      .book-appt-form-select-date-input {
        height: 37px;
        font-size: 14px;
        border: 1.5px solid $secondary;
        padding: 0px 20px;
        border-radius: 2.5px;
        color: $secondary;
        letter-spacing: 0.75px;
        font-weight: 600;
        background-color: $white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        .book-appt-form-calendar-date-placeholder {
          color: #a9a9a9;
        }

        .book-appt-form-calendar-icon-wrapper {
          position: relative;
          width: 30px;

          .book-appt-form-calendar-icon {
            position: absolute;
            right: 0;
            top: -14px;
            object-fit: cover;
            width: 100%;
          }
        }
      }
    }

    .book-appt-form-button {
      height: 40px;
      width: 30%;
      min-width: 125px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      cursor: pointer;
      border-radius: 2.5px;
      margin: 0 auto;
      background-color: $primary;
      color: $white;
      font-weight: 600;
      letter-spacing: 0.75px;

      &:hover {
        background-color: $primary-varient;
      }
    }
  }

  .book-appt-outro-wrapper {
    padding: 40px;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    gap: 40px;
    text-align: center;
    

    .book-appt-outro-header {
      font-size: 28px;
      font-weight: 600;
      color: $secondary;
      width: 80%;
      line-height: 40px;
    }

    .book-appt-outro-text {
      font-size: 20px;
      color: $dark;
      line-height: 40px;
    }
  }

  .book-appt-successful-sub-wrapper {
    background-color: $off-white;
    padding: 40px;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    gap: 40px;
    text-align: center;

    .book-appt-successful-sub-icon-group-one {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .book-appt-successful-sub-icon-two-wrapper {
        transform: scaleX(-1);
      }
    }

    .book-appt-successful-sub-header {
      font-size: 24px;
      font-weight: 600;
      color: $secondary;
      width: 80%;
    }

    .book-appt-successful-sub-text {
      font-size: 20px;
      color: $dark;
    }

    .book-appt-successful-sub-icon-group-two {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
}

.book-appt-form-input-error {
  color: #fd1e46;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.react-datepicker__day--selected {
  background-color: $secondary !important;
}

.react-datepicker__header {
  background-color: $secondary !important;
}

.react-datepicker__current-month {
  color: $white !important;
}

.react-datepicker__day-name {
  color: $white !important;
}

.react-datepicker__time-container {
  width: unset !important;
}

.react-datepicker__time-box {
  width: unset !important;
}

.react-datepicker__navigation--next {
  right: -2px !important;
}

.react-datepicker-time__header {
  color: $white !important;
}

.react-datepicker__input-container {
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
}

.react-datepicker__calendar-icon {
  padding: 0px !important;
  left: 20px !important;
  fill: $secondary !important;
}

.react-datepicker__view-calendar-icon input {
  height: 37px;
  border: 1.5px solid $secondary;
  padding: 0px 45px !important;
  border-radius: 2.5px;
  color: $primary;
  letter-spacing: 0.75px;
  font-weight: 600;
  width: 100%;
  font-size: 14px;
  cursor: pointer;

  &:focus-visible {
    outline: none;
  }

  &::placeholder {
    color: #a9a9a9 !important;
  }
}

.react-datepicker__time-list-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-datepicker__time-list-item--selected {
  background-color: $secondary !important;
}

.react-datepicker__time-list-item--disabled {
  display: none;
}

.react-datepicker__day--keyboard-selected {
  background-color: $off-white !important;
}

.react-datepicker__day--outside-month {
  color: transparent !important;
  pointer-events: none;
}
