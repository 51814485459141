@media (max-width: 650px) {
  .login-container {
    .login-wrapper {
      grid-template-columns: 1fr;

      .login-main-content-wrapper {
        .login-header {
          font-size: 20px;
        }
  
        .login-form-wrapper {
          .login-input-group-wrapper {  
            .login-input-label {
              font-size: 16px;
            }
          }

          .login-button {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .login-container {
    padding-top: 40px;
  
    .login-wrapper {
      padding: 40px 20px;
    }
  }
}