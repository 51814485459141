body {
  margin: 0;
  letter-spacing: 0.75px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// *** Navigation ***

@import url(../Navbar/navbarMainStyles.scss);
@import url(../Navbar/navbarMediaQueries.scss);
@import url(../Navbar/sidenavMainStyles.scss);
@import url(../Navbar/sidenavMediaQueries.scss);

// *** Home ***

@import url(../Home/homeMainStyles.scss);
@import url(../Home/homeMediaQueries.scss);

// *** Footer ***

@import url(../Footer/footerMainStyles.scss);
@import url(../Footer/footerMediaQueries.scss);

// *** About Us ***

@import url(../About/aboutUsMainStyles.scss);
@import url(../About/aboutUsMediaQueries.scss);

// *** Book Appt ***

@import url(../BookAppt/bookApptMainStyles.scss);
@import url(../BookAppt/bookApptMediaQueries.scss);

// *** Service ***

@import url(../Services/serviceMainStyles.scss);
@import url(../Services/serviceMediaQueries.scss);

// *** Photo Gallery ***

@import url(../PhotoGallery/photoGalleryMainStyles.scss);
@import url(../PhotoGallery/photoGalleryMediaQueries.scss);
@import url(../PhotoGallery/photoGalleryFormMainStyles.scss);
@import url(../PhotoGallery/photoGalleryFormMediaQueries.scss);

// *** Login ***

@import url(../Login/loginMainStyles.scss);
@import url(../Login/loginMediaQueries.scss);

// *** Page Not Found ***

@import url(../PageNotFound/pageNotFoundStyles.scss);

// *** Financing ***

@import url(../Financing/financingMainStyles.scss);
@import url(../Financing/financingMediaQueries.scss);