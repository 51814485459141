@import "../VarsAndMixins/colors.scss";

.navbar-wrapper {
  width: calc(100% - 40px);
  height: 40px;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  background-color: $secondary;
  position: fixed;
  z-index: 100;
  
  .menu-toggle {
    font-size: 18px;  
    color: $white;
    cursor: pointer;
    position: absolute;
  }
  
  .logo-wrapper {
    width: 100%;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    text-transform: uppercase;
    font-size: 15px;
  }
  
  .top-nav-call-us-toggle {
    font-size: 16px;
    color: $white;
    cursor: pointer;
    position: absolute;
    right: 20px; 
    order: 2;
    
    .top-nav-call-us-text {
      position: absolute;
      transition: 0.5s ease-in-out;
      font-size: 14px;
      right: 20px;
      order: 0;
      width: 120px;
      overflow: hidden;
      color: transparent !important;

      &::before {
        content: "435-962-9535";
        position: absolute;
        color: $white;
        right: -200px;
        width: 120px;
        transition: opacity 750ms ease-in-out, right 500ms ease-in-out;
        transition-property: opacity, right; 
        font-weight: 600;
        font-size: 14px;
        opacity: 0;
      }
    }

    .top-nav-call-us-icon {
      transform: rotate(230deg);
      font-size: 15px;
      background-color: transparent;
      animation-name: callusjiggle;
      animation-duration: 10s;
      animation-delay: 10s;
      animation-iteration-count: infinite;

    }

    &:hover {
      .top-nav-call-us-text::before {
        right: -4px;
        opacity: 1;
      }
    }
  }
}

@keyframes callusjiggle {
  0% {
    transform: rotate(230deg)
  }
  49% {
    transform: rotate(230deg)
  }
  50% {
    transform: rotate(250deg) scale(1.1)
  }
  51% {
    transform: rotate(230deg)
  }
  52% {
    transform: rotate(250deg)
  }
  53% {
    transform: rotate(230deg) scale(1)
  }
  100% {
    transform: rotate(230deg)
  }
}