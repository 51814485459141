@import "../VarsAndMixins/colors.scss";

.homepage-wrapper {
  padding-top: 40px;

  // *** Home Hero Section ***
  .homepage-home-hero-wrapper {
    position: relative;
    overflow: hidden;
  
    .homepage-home-hero-background {
      height: calc(90vh - 40px);
      background-image: url(../../Images/Landscape/landscape-13.jpg);
      background-color: transparent;
      background-position: center;
      background-repeat: no-repeat;
      object-fit: cover;
      background-size: cover;
      filter: brightness(60%);
      
      @supports not (-webkit-touch-callout: none) { 
        background-attachment: fixed;
      }      
    }
  
    .homepage-home-hero-content-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
  
      .homepage-home-hero-content-wrapper {
        text-align: center;
        font-size: 28px;
        display: flex;
        height: 80%;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        padding: 0px 40px;
        line-height: 40px;
  
        .homepage-home-hero-content-header {
          color: #FFF;
          font-weight: 600;
          width: 70%;
          text-align: left;
          font-size: 28px;
        }
  
        .homepage-home-hero-content-subtext {
          font-weight: 600;
          font-size: 20px;
          margin-top: 30px;
          padding: 0px 20px;
          height: 40px;
          background-color: $primary;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 2.5px;
          color: #efefef;
          cursor: pointer;
          text-decoration: none;

          &:hover {
            background-color: $primary-varient;
          }
        }
      }
    }

    .homepage-home-hero-image-skew-one {
      position: absolute;
      width: 100%;
      height: 150px;
      background-color: #F0F0F0 !important;
      transform: skewY(350deg);
      bottom: -75px;
    }

    .homepage-home-hero-image-skew-two {
      position: absolute;
      width: 100%;
      height: 150px;
      background-color: #F0F0F0 !important;
      transform: skewY(25deg);
      left: -150px;
      bottom: -100px;
    }
  }

  // *** Introduction Section
  .homepage-introduction-section-wrapper {
    padding: 40px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;

    .homepage-introduction-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;

      .homepage-introduction-wrapper {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
        
        .homepage-introduction-header {
          font-size: 20px;
          color: $secondary;
          font-weight: 600;
          margin: 0px;
          line-height: 30px;
        }

        .homepage-introduction-subheader {
          font-weight: 600;
          font-size: 18px;
          color: $primary;
          margin: 0px;
          line-height: 30px;
        }

        .homepage-introduction-text {
          font-size: 16px;
          line-height: 30px;
        }
      }

      .homepage-introduction-image-wrapper {
        display: flex;
        width: 100%;
        border-radius: 2.5px;
        overflow: hidden;

        .homepage-introduction-image {
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .homepage-introduction-testimonials-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px;
      overflow-x: auto;
      /* Hide scrollbar for IE, Edge and Firefox */
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }


      .homepage-introduction-testimonial-wrapper {
        padding: 40px 20px;
        background-color: $primary;
        text-align: center;
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
        color: $white;
        min-width: 250px;
        line-height: 30px;

        .homepage-introduction-testimonial-text {
          font-size: 18px;
        }

        .homepage-introduction-testimonial-author {
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  // *** Homepage Services ***
  .homepage-services-wrapper {
    background-color: $off-white;
    padding: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;

    .homepage-service-wrapper {
      position: relative;
      width: 100%;
      height: calc((100vw - 75vw) - 40px + 1.25px);
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &::after {
        content: "";
        position: absolute;
        right: -11px;
        height: 100%;
        width: 2px;
        background-color: $backgroundSurface;
      }

      .homepage-service-icon-wrapper {
        position: relative;
        height: 100%;
        max-height: 100px;
        margin: 0 auto;

        .homepage-service-icon {
          height: 100%;
          object-fit: cover;
        }
      }

      .homepage-service-text {
        padding-top: 20px;
        font-weight: 600;
        font-size: 18px;
        color: $secondary;
      }
    }

    .homepage-service-wrapper:nth-child(4)::after  {
      display: none;
    }
  
    .homepage-service-wrapper:nth-child(8)::after  {
      display: none;
    }
  
    .homepage-service-wrapper:nth-child(-n + 4)::before  {
      content: "";
      position: absolute;
      left: 0px;
      bottom: -11px;
      height: 2px;
      width: 100%;
      background-color: $backgroundSurface;
    }
  }

  .homepage-outro-wrapper {
    padding: 40px 40px 80px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;

    .homepage-outro-header {
      font-size: 24px;
      color: $secondary;
      font-weight: 600;
      margin: 0px;
      line-height: 40px;
    }

    .homepage-outro-text {
      font-size: 20px;
      color: $dark;
      line-height: 40px;

    }
  }
}