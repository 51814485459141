@media (max-width: 650px) {
  .photo-gallery-container {
    .photo-gallery-wrapper {
      grid-template-columns: 1fr;

      .photo-gallery-main-content-wrapper {
        .photo-gallery-header {
          font-size: 20px;
        }
  
        .photo-gallery-form-wrapper {
          .photo-gallery-input-group-wrapper {  
            .photo-gallery-input-label {
              font-size: 16px;
            }
          }

          .photo-gallery-button {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .photo-gallery-container {
    padding-top: 40px;
  
    .photo-gallery-wrapper {
      padding: 40px 20px;
    }
  }
}