@import "../VarsAndMixins/colors.scss";

.footer-wrapper {
  background-color: $secondary;
  padding: 40px;
  width: calc(100% - 80px);
  margin-top: 100px;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;

  &::before {
    content: "";
    display: block;
    left: 0;
    top: -99px;
    position: absolute;
    width: 100%;
    height: 100px;
    background-color: $secondary;
    -webkit-mask-position: top;
    mask-position: top;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
    transform: scaleY(-1) scaleX(-1);
    -webkit-mask-image: url(../../Images/About/bubble-section-top.svg);
    mask-image: url(../../Images/About/bubble-section-top.svg);
    pointer-events: none;
  }

  .footer-logo-image-wrapper {
    width: 100%;

    .footer-logo-image {
      width: 130%;
      margin-left: -20%;
      object-fit: cover;
    }
  }

  .footer-info-section-wrapper {
    display: grid; 
    grid-template-columns: 1fr;
    gap: 40px;
    padding-right: 20px;

    .footer-info-section-trademark-info {
      color: $white;
      font-weight: 400;
      line-height: 25px;
      font-size: 15px;
    }

    .footer-info-section-copyright-info {
      color: $white;
      font-weight: 400;
      line-height: 25px;
      font-size: 15px;
    }

    .footer-info-section-contact-us-wrapper {
      font-weight: 400;
      line-height: 25px;
      font-size: 15px;
      
      .footer-info-section-contact-us-header {   
        color: $white;
      }

      .footer-info-section-contact-us-phone {
        color: $white;
        cursor: pointer;
        text-decoration: underline;

        &:hover {
          font-weight: 600;
        }
      }
    }
  }

  .footer-links-container {
    .footer-links-header {
      font-weight: 600;
      line-height: 25px;
      font-size: 15px;
      color: $white;
    }

    .footer-links-wrapper {
      margin-top: 20px;
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;

      .footer-link-wrapper {
        color: $white;
        font-weight: 400;
        line-height: 25px;
        font-size: 15px;
        cursor: pointer;
        text-decoration: none;

        &:hover {
          font-weight: 600;
        }
      }
    }
  }

  .footer-links-group-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .footer-links-container-with-margin {
    margin-top: 40px;
  }

  .footer-socials-and-locations-wrapper {
    .footer-locations-container {
      .footer-locations-header {
        font-weight: 600;
        line-height: 25px;
        font-size: 15px;
        color: $white;
      }

      .footer-locations-wrapper {
        margin-top: 20px;
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
        font-size: 14px;

        .footer-location-wrapper {
          display: flex;

          .footer-location-icon-wrapper {
            color: $white;
            font-weight: 400;
          }
          
          .footer-location {
            margin-left: 10px;
            color: $white;
            font-weight: 400;
            font-size: 15px;
          }
        } 
      }
    }

    .footer-socials-container {
       margin-top: 20px;

      .footer-socials-header {
        font-weight: 600;
        line-height: 25px;
        font-size: 15px;
        color: $white;
      }
  
      .footer-socials-wrapper {
        margin-top: 20px;
        display: grid;
        grid-template-columns: 35px 35px;
        gap: 20px;
    
        .footer-social-wrapper {
          height: 30px;
          width: 30px;
          padding: 2.5px;
          background-color: #ffffff1a;
          color: $white;
          font-weight: 400;
          font-size: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          cursor: pointer;
  
          &:hover {
            transform: scale(1.02);
          }
        }
      }
    }
  }
}
