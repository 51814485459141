@media (max-width: 800px) {
  .book-appt-main-content-wrapper {
    grid-template-columns: 1fr !important;

    .book-appt-left-column-wrapper {
      display: none !important;
    }
  }
}

@media (max-width: 650px) {
  .book-appt-wrapper {
    .book-appt-intro-wrapper {
      
      .book-appt-intro-header {
        font-size: 24px;
      }
  
      .book-appt-intro-text {
        font-size: 18px;
      }
    }

    .book-appt-successful-sub-icon-one-wrapper {
      width: 25% !important;

      .book-appt-successful-sub-icon-one {
        width: 100%;
      }

      .book-appt-successful-sub-icon-two {
        width: 100%;
      }
    }

    .book-appt-successful-sub-icon-two-wrapper {
      width: 25% !important;

      .book-appt-successful-sub-icon-one {
        width: 100%;
      }

      .book-appt-successful-sub-icon-two {
        width: 100%;
      }
    }

    .book-appt-outro-wrapper {
      .book-appt-outro-header {
        font-size: 24px;
      }
  
      .book-appt-outro-text {
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 400px) {
  .book-appt-wrapper {
    .book-appt-intro-wrapper {
      padding: 40px 20px;
    }

    .book-appt-form-wrapper {
      padding: 40px 20px;
    }

    .book-appt-outro-wrapper {
      padding: 40px 20px;
      .book-appt-intro-text {
        line-height: 30px;
      }
    }
  }
}