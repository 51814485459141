@import "../VarsAndMixins/colors.scss";

.login-container {
  padding-top: 40px;

  .login-wrapper {
    padding: 40px;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;

    .login-image-wrapper {
      width: 100%;

      .login-image {
        width: 100%;
        object-fit: cover;
        border-radius: 2.5px;
      }
    }

    .login-main-content-wrapper {
      display: grid;
      grid-template-columns: 1fr;
      gap: 40px;

      .login-header {
        font-weight: 600;
        color: $secondary;
        font-size: 28px;
        text-align: center;
      }

      .login-form-wrapper {
        display: grid;
        grid-template-columns: 1fr;
        gap: 40px;

        .login-input-group-wrapper {
          display: grid;
          grid-template-columns: 1fr;
          gap: 20px;

          .login-input-label {
            color: $secondary;
            font-size: 18px;
            font-weight: 600;
          }

          .login-input {
            height: 37px;
            border: 1.5px solid $secondary;
            border-radius: 2.5px;
            font-size: 15px;
            padding: 0px 20px;
            color: $secondary;
            letter-spacing: 0.75px;
            background-color: $off-white;

            &::placeholder {
              font-size: 14px;
              color: #a9a9a9;
            }

            &:focus-visible {
              outline: none;
            }
          }

          .login-input-error {
            color: #fd1e46;
            font-weight: 600;
            font-size: 15px;
          }
        }

        .login-error {
          color: #fd1e46;
          font-weight: 600;
          font-size: 15px;
          text-align: center;
        }

        .login-button {
          height: 40px;
          letter-spacing: 0.75px;
          border: none;
          padding: 0px 20px;
          cursor: pointer;
          text-align: center;
          background-color: $primary;
          color: $white;
          font-weight: 600;
        }
      }
      
      .login-successful-wrapper {
        padding-bottom: 10px;

        .login-successful-text {
          text-align: center;
          color: $dark;
          line-height: 25px;
        }
      }
    }
  }
}