@import "../VarsAndMixins/colors.scss";

.service-container {
  padding-top: 40px;

  .service-intro-container {
    padding: 40px;
    display: grid;
    grid-template-columns: 0.6fr 0.4fr;
    gap: 40px;
    
    .service-intro-wrapper {
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;

      .service-intro-header {
        font-size: 28px;
        color: $secondary;
        font-weight: 600;
        line-height: 40px;
      }

      .service-intro-text {
        font-size: 20px;
        color: $dark;
        line-height: 40px;
      }
    }

    .service-intro-image-wrapper {
      width: 100%;
      border-radius: 2.5px;
      overflow: hidden;
      max-height: 250px;

      .service-intro-image {
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .design-and-build-service-intro-container {
    position: relative;
    padding: 40px;
    display: flex;
    
    .design-and-build-service-intro-wrapper {
      padding-left: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      gap: 0px;

      .design-and-build-service-intro-header {
        padding-bottom: 40px;
        font-size: 28px;
        color: $secondary;
        line-height: 40px;
        font-weight: 600;
      }

      .design-and-build-service-intro-text {
        font-size: 20px;
        color: $dark;
        line-height: 40px;
      }
    }

    .design-and-build-service-intro-image-wrapper {
      position: relative;
      // top: -20px;
      width: 100%;
      border-radius: 2.5px;
      overflow: hidden;
      max-height: 550px;

      .design-and-build-service-intro-image {
        width: 100%;
        object-fit: cover;
        transform: scale(1.65)
      }
    }
  }

  .services-alt-section-image-wrapper {
    width: calc(100% - 40px);
    padding-right: 40px;
    
    .services-alt-section-image {
      margin-bottom: 40px;
      border-radius: 2.5px;
      width: 100%;
      display: flex;
    }
  }

  .alt-services-main-content-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 40px !important;
    column-gap: 0px !important;
    align-items: center;
    background-color: $secondary-off-white;
  }

  .services-main-content-container {
    line-height: 40px;
    background-color: $secondary-off-white;
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;
    margin-top: 100px;

    &::before {
      content: "";
      display: block;
      left: 0;
      top: -99px;
      position: absolute;
      width: 100%;
      height: 100px;
      background-color: $secondary-off-white;
      -webkit-mask-position: top;
      mask-position: top;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: 100% 100%;
      mask-size: 100% 100%;
      transform: scaleY(-1) scaleX(-1);
      -webkit-mask-image: url(../../Images/About/bubble-section-top.svg);
      mask-image: url(../../Images/About/bubble-section-top.svg);
      pointer-events: none;
    }

    .services-main-content-intro-wrapper {
      padding: 40px 40px 0px;
      text-align: center;
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;

      .services-main-content-intro-header {
        font-size: 24px;
        color: $secondary;
        font-weight: 600;
      }

      .services-main-content-intro-text {
        font-size: 20px;
        color: $dark;
        line-height: 40px;
      }
    }

    .services-main-content-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 40px;
      padding: 0px 40px 40px;

      .service-wrapper {
        padding: 20px;
        text-align: center;
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
        background-color: #f9f8f8;
        border-radius: 5px;

        .service-header {
          font-size: 20px;
          font-weight: 600;
          color: $secondary
        }
  
        .service-text {
          color: $dark;
          line-height: 40px;
        }
      }
    }

    .alt-services-main-content-wrapper {
      grid-template-columns: 1fr;
    }
  }

  .services-main-content-container-alt {
    margin: 0px;

    &::before {
      display: none;
    }
  }

  .services-main-content-container::before {
    transform: scaleX(1) scaleY(-1);
  }

  .service-outro-wrapper {
    padding: 40px;
    text-align: center;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    line-height: 40px;

    .service-outro-header {
      font-size: 28px;
      color: $secondary;
      font-weight: 600;
    }

    .service-outro-text {
      font-size: 20px;
      color: $dark;
    }

    .service-outro-action-button {
      height: 40px;
      background-color: $primary;
      color: $white;
      font-weight: 600;
      width: 20%;
      min-width: 125px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      cursor: pointer;
      text-decoration: none;
      margin: 5px auto 0px;

      &:hover {
        background-color: $primary-varient;
      }
    }
  }
}