.financing-container {
  padding-top: 40px;

  .financing-intro-container {
    padding: 40px;
    min-height: 80vh;
    width: calc(100% - 80px);
    background-image: url(../../Images//Financing/intro-background.jpg);
    background-size: cover;
    background-color: #FFF !important;
    background-position-y: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 80px;
    align-items: center;

    .financing-intro-wrapper {
      display: grid;
      grid-template-columns: 1fr;
      gap: 40px;

      .financing-intro-header {
        font-size: 28px;
        color: #818589;
        font-weight: 600;
        line-height: 40px;
      }

      .financing-intro-text {
        color: #1d1d1d;
        line-height: 30px;
      }

      .financing-intro-action-btn {
        height: 40px;
        background-color: #667c3e;
        color: #FFF;
        font-weight: 600;
        width: fit-content;
        padding: 0px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        cursor: pointer;
        text-decoration: none;
      }
    }
  }

  .financing-info-wrapper {
    width: calc(100% - 80px);
    padding: 40px;
    min-height: calc(100vh - 80px - 40px);
    background-image: url(../../Images//Financing/intro-background.svg);
    background-size: cover;
    display: flex;
    flex-direction: column;
    gap: 80px;
    justify-content: center;

    .financing-info-intro-wrapper {
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;

      .financing-info-intro-header {
        font-size: 28px;
        color: #818589;
        font-weight: 600;
        line-height: 40px;
      }

      .financing-info-intro-text {
        color: #1d1d1d;
        line-height: 30px;
      }
    }

    .financing-info-cards-wrapper {
      display: grid;
      grid-template-columns: minmax(50px, 325px) minmax(50px, 325px) minmax(50px, 325px);
      gap: 40px;
      justify-content: center;

      .financing-info-card-wrapper {
        min-height: 40vh;
        // height: calc(100% - 80px);
        border-radius: 5px;
        background: #FFF;
        padding: 40px 20px;
        max-width: 275px;
        -webkit-box-shadow: 0px 21px 66px -24px rgba(0,0,0,0.59);
        -moz-box-shadow: 0px 21px 66px -24px rgba(0,0,0,0.59);
        box-shadow: 0px 21px 66px -24px rgba(0,0,0,0.59);
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;

        .financing-info-card-icon {
          font-size: 28px;
          color: #818589;
        }
        
        .financing-info-card-header {
          font-size: 16px;
          color: #000;
          font-weight: 600;
        }

        .financing-info-card-text {
          font-size: 16px;
          line-height: 30px;
          color: #000;
        }

        .financing-info-card-action-btn {
          font-size: 13px;
          padding: 2.5px 10px;
          background-color: #667c3e;
          width: fit-content;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px; 
          cursor: pointer;
          text-decoration: none;
          color: #FFF;
          font-weight: 600;
        }
      }
    }
  }

  .financing-faq-wrapper {
    width: calc(100% - 80px);
    padding: 40px 40px 80px;
    display: flex;
    flex-direction: column;
    gap: 80px;
    justify-content: center;

    .financing-faq-intro-wrapper {
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;

      .financing-faq-intro-header {
        font-size: 28px;
        color: #818589;
        font-weight: 600;
        line-height: 40px;
      }

      .financing-faq-intro-text {
        color: #1d1d1d;
        line-height: 30px;
      }
    }

    .financing-faq-cards-wrapper {
      display: grid;
      grid-template-columns: minmax(50px, 450px) minmax(50px, 450px);
      gap: 40px;
      justify-content: space-between;

      .financing-faq-card-wrapper {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;

        .financing-faq-card-header {
          font-size: 16px;
          font-weight: 600;
          color: #000;
        }

        .financing-faq-card-text {
          font-size: 16px;
          line-height: 30px;

          .financing-card-link {
            color: #000;

            &:hover {
              color: #667c3e;
            }
          }
        }
      }
    }
  }
}