@media (max-width: 850px) {
  .footer-wrapper {
    grid-template-columns: 1fr;
    gap: 40px;
  
    .footer-logo-image-wrapper {
      width: 60%;
      max-width: 250px;
      min-width: 150px;
    }
  
    .footer-info-section-wrapper {
      gap: 20px;
      padding-right: 0px;
    }
  
    .footer-links-container {  
      .footer-links-wrapper {
        margin-top: 20px;
      }
    }
  
    .footer-socials-container {
      .footer-socials-wrapper {
        margin-top: 20px;
      }
    }
  }
}

@media (max-width: 450px) {
  .footer-wrapper {
    margin-top: 60px;

    &::before {
      height: 60px;
      top: -59px;
    }
  }
}

@media (max-width: 400px) {
  .footer-wrapper {
    margin-top: 40px;
    padding: 40px 20px;
    width: calc(100% - 40px);

    &:before {
      height: 40px;
      top: -39px;
    }
  }
}