@import "../VarsAndMixins/colors.scss";

.side-nav-container {
  position: fixed;
  background-color: rgba(29, 29, 29, 0.7);
  z-index: 100;
  top: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;

  .side-nav-wrapper {
    background: $secondary;
    color: $white;
    width: 80%;
    max-width: 570px;
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    transform: translateX(-570px);
    transition: height 0.5s, transform 0.5s;
    display: flex;
    flex-direction: column;

    .side-nav-heading-wrapper {
      padding: 30px 40px 0px;
      box-shadow: $secondary 0px 19px 16px 6px;
      z-index: 100;

      .side-nav-close-modal-header {
        width: 25px;
        height: 25px;
        margin-left: -4px;
        cursor: pointer;

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    .side-nav-main-content {
      padding: 20px 40px;
      font-size: 14px;
      height: calc(100% - 45px - 55px - 40px);
      overflow-y: auto;
      overflow-x: hidden;
      transform: translateX(-570px);
      transition: transform 0.7s;

      .side-nav-main-links-wrapper {
        font-weight: 400;
        letter-spacing: 0.75px;

        .side-nav-link-wrapper {
          border-bottom: 1px solid rgba(255, 255, 255, 0.25);
          padding: 20px 0px;

          .side-nav-services-link-header-wrapper {
            display: flex;
            justify-content: space-between;
            cursor: pointer;

            &:hover {
              letter-spacing: 0.85px;
            }

            .side-nav-services-link-header {
              font-weight: 600;
            }

            .side-nav-services-link-header-icons-wrapper {
              position: relative;
              font-size: 16px;

              .side-nav-services-link-header-icon-wrapper {
                position: absolute;
                left: -13px;
                transform: rotateZ(0deg);
                transition: transform 0.5s ease-in-out;
              }

              .side-nav-services-link-header-icon-two-wrapper {
                transform: rotateZ(90deg);
              }
            }
          }

          .side-nav-services-links-wrapper {
            padding: 40px 20px 20px;
            display: grid;
            grid-template-columns: 1fr;
            gap: 20px;

            .side-nav-services-link-wrapper {
              cursor: pointer;
              text-decoration: none;
              color: $white;

              &:hover {
                letter-spacing: 0.85px;
              }
              
              &:nth-child(last-child) {
                padding-bottom: 20px;
              }
            }
          }

          .side-nav-link {
            text-decoration: none;
            color: $white;
            font-weight: 600;

            &:hover {
              letter-spacing: 0.85px;
            }
          }
        }

        .side-nav-services-link-container {
          max-height: 17px;
          overflow: hidden;
          transition: max-height 0.5s ease-in-out;
        }
      }

      .side-nav-secondary-links-wrapper {
        margin-top: 20px;
        font-size: 12px;

        .side-nav-link-wrapper {
          padding-bottom: 20px;
          text-decoration: none;

          .side-nav-link {
            cursor: pointer;
            display: inline-block;
            text-decoration: none;
            color: $white;

            &:hover {
              font-weight: 600;
            }
          }
        }
      }
    }

    .side-nav-socials-wrapper {
      background: $secondary;
      height: 45px;
      font-size: 20px;
      position: absolute;
      bottom: 0;
      width: 100%;
      box-shadow: $secondary 0px -19px 16px 6px;
      z-index: 100;
      transform: translateY(145px);
      transition: transform 0.7s;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      overflow: hidden;

      .side-nav-social-link {
        cursor: pointer;
        text-decoration: none;
        color: $white;
      }
    }
  }
}

.side-nav-slide {
  transform: translateX(0px) !important;
}

.side-nav-main-content::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.side-nav-main-content::-webkit-scrollbar-track {
  background: rgb(28, 27, 27);
}

/* Handle */
.side-nav-main-content::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
  // background: #fff;
  border-radius: 10px;
}

.side-nav-main-content-slide, .side-nav-footer-slide {
  transform: translateX(0px) !important;
}

.open-content {
  max-height: calc(168px + 40px + 17px) !important;
}

.spin-one {
  transform: rotateZ(360deg) !important;
}

.spin-two {
  transform: rotate(360deg) !important;
}