@media (max-width: 950px) {
  .service-container {
    .service-intro-container {
      grid-template-columns: 1fr;

      .service-intro-image-wrapper {
        max-height: 400px;
      }
    }
  }
}

@media (max-width: 900px) {
  .service-container {
    .design-and-build-service-intro-container {
      gap: 20px;
      
      .design-and-build-service-intro-wrapper {
        padding-left: 0px;
  
        .design-and-build-service-intro-header {
          padding-bottom: 40px;
          line-height: 40px;
        }
  
        .design-and-build-service-intro-text {
          line-height: 40px;
        }
      }
  
      .design-and-build-service-intro-image-wrapper {
        .design-and-build-service-intro-image {
          transform: scale(1.75);
        }
      }
    }
  }
}

@media (max-width: 750px) {
  .service-container {
    .service-intro-container {
      grid-template-columns: 1fr;
  
      .service-intro-image-wrapper {
        max-height: 350px;
      }
    }

    .design-and-build-service-intro-container {
      gap: 40px;
      flex-direction: column;
      
      .design-and-build-service-intro-wrapper {
        padding-left: 0px;
  
        .design-and-build-service-intro-header {
          padding-bottom: 40px;
          line-height: 30px;
        }
  
        .design-and-build-service-intro-text {
          line-height: 30px;
        }
      }
  
      .design-and-build-service-intro-image-wrapper {
        order: 2;
        max-height: 600px;
        top: -20px;

        .design-and-build-service-intro-image {
          transform: scale(1);
        }
      }
    }
  
    .services-main-content-container {
      .services-main-content-wrapper {
        grid-template-columns: 1fr;
        
        .service-wrapper {
          padding: 40px;
        }
      }
    }

    .alt-services-main-content-container {
      grid-template-columns: 1fr !important;
      gap: 0px !important;

      .services-alt-section-image-wrapper {
        width: 100%;
        padding-right: 0px;

        .services-alt-section-image {
          margin: 0px;
        }
      }
    }
  }
}

@media (max-width: 650px) {
  .service-container {
    .service-intro-container {
      grid-template-columns: 1fr;

      .service-intro-wrapper {
        .service-intro-header {
          font-size: 24px;
        }
  
        .service-intro-text {
          font-size: 18px;
        }
      }
  
      .service-intro-image-wrapper {
        max-height: 350px;
      }
    }
  
    .services-main-content-container {
      .services-main-content-intro-wrapper {
        .services-main-content-intro-header {
          font-size: 24px;
        }
  
        .services-main-content-intro-text {
          font-size: 18px;
        }
      }

  
      .services-main-content-wrapper {
        grid-template-columns: 1fr;
        
        .service-wrapper {
          padding: 40px;
  
          .service-header {
            font-size: 20px;
          }
    
          .service-text {
            font-size: 16px;
          }
        }
      }
    }

    .service-outro-wrapper {
      .service-outro-header {
        font-size: 24px;
      }
  
      .service-outro-text {
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 450px) {
  .service-container {
    .services-main-content-container {
      margin-top: 60px;

      &::before {
        height: 60px;
        top: -59px;
      }
    }
  }
}

@media (max-width: 400px) {
  .service-container {
    .service-intro-container {
      padding: 40px 20px;
    }
    
    .design-and-build-service-intro-container {
      gap: 50px;
      .design-and-build-service-intro-wrapper {
        .design-and-build-service-intro-header {
          padding-bottom: 20px;
        }
      }
      .design-and-build-service-intro-image-wrapper {
        .design-and-build-service-intro-image {
          transform: scale(1.5);
        }
      }
    }

    .services-main-content-intro-wrapper {
      .services-main-content-intro-text {
        line-height: 30px !important;
      }
    }

    .services-main-content-container {
      margin-top: 40px;
      line-height: 30px !important;

      &::before {
        height: 40px;
        top: -39px;
      }

      .services-main-content-intro-wrapper {
        padding: 40px 20px ;
      }
  
      .services-main-content-wrapper {
        padding: 0px 20px 40px;
        
        .service-wrapper {
          padding: 40px 20px;

          .service-text {
            line-height: 30px;
          }
        }
      }
    }
  
    .service-outro-wrapper {
      padding: 40px 20px;
      line-height: 30px;
    }
  }
}